<template>
  <div class="app">
    <el-dialog
      title="下载二维码"
      :visible.sync="dialogSendVisible"
      :width="qrcodeData.dialogWidth"
      :before-close="handleSendClose"
    >
      <el-timeline>
        <div class="container">
          <div class="share-img">
            <img :src="imgUrl" alt="" />
          </div>
          <div
            :class="[qrcodeData.type == 1 ? 'creat-img1' : 'creat-img2']"
            ref="box"
          >
            <img :src="qrcodeData.bgcolor" />
            <div id="qrcode" class="qrcode"></div>
            <span class="agent-text"
              >联盟商家 NO. {{ qrcodeData.data.payQrcodeNo }}</span
            >
          </div>
        </div>
      </el-timeline>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleSendClose">取 消</el-button>
        <el-button type="primary" @click="downloadImg">下载</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { qrcanvas } from "qrcanvas";
import html2canvas from "html2canvas";
import { qrCodeUrl } from "@/plugins/api";
export default {
  data() {
    return {
      imgUrl: "",
      dialogSendVisible: false,
      imgStream: "",
      width: 596,
      height: 851,
    };
  },
  props: {
    qrcodeData: Object,
  },
  watch: {
    imgUrl() {
      //监听到imgUrl有变化以后 说明新图片已经生成 隐藏DOM
      this.$refs.box.style.display = "none";
    },
  },
  created() {
    this.dialogSendVisible = true;
    if (this.qrcodeData.type === 2) {
      this.width = 454;
      this.height = 681;
    }
    this.$nextTick(() => {
      //生成二维码
      const canvas1 = qrcanvas({
        data: qrCodeUrl + this.qrcodeData.data.payQrcodeNo,
        size: this.qrcodeData.size,
      });
      document.getElementById("qrcode").innerHTML = "";
      document.getElementById("qrcode").appendChild(canvas1);

      //合成分享图

      html2canvas(this.$refs.box).then((canvas) => {
        this.imgStream = this.imgUrl = URL.createObjectURL(
          this.base64ToBlob(canvas.toDataURL())
        );
      });
    });
  },

  methods: {
    //base64转blob
    base64ToBlob(code) {
      let parts = code.split(";base64,");
      let contentType = parts[0].split(":")[1];
      let raw = window.atob(parts[1]);
      let rawLength = raw.length;

      let uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    },
    handleSendClose() {
      this.dialogSendVisible = false;
      this.$emit("dialog", false);
    },
    downloadImg() {
      const image = new Image();
      image.src = this.imgStream; //给图片对象写入base64编码的svg流

      const canvas = document.createElement("canvas"); //准备空画布
      canvas.width = this.width; //设置canvas的尺寸
      canvas.height = this.height;

      const context = canvas.getContext("2d"); //取得画布的2d绘图上下文
      image.onload = () => {
        //image.onload图片加载完成后才开始绘制canvas 否则会出现下载的图片为空的清空
        context.drawImage(image, 0, 0); //image是要绘制的图片路径（实际是一堆path坐标）
        const a = document.createElement("a");
        a.href = canvas.toDataURL("image/png"); //将画布内的信息导出为png图片数据
        a.download = "二维码下载（" + this.qrcodeData.data.payQrcodeNo + "）"; //设定下载名称
        a.click(); //点击触发下载
      };
    },
  },
};
</script>
<style lang='scss' scoped>
.creat-img1 {
  position: relative;
  height: 851px;
  width: 596px;
  img {
    z-index: 3;
    height: 851px;
    width: 596px;
  }
  .qrcode {
    position: absolute;
    top: 18rem;
    left: 50%;
    margin-left: -124px;
    z-index: 5;
  }
  .agent-text {
    z-index: 99;
    position: absolute;
    left: 50%;
    margin-left: -138px;
    top: 550px;
    color: #000000;
    font-size: 14px;
    text-align: center;
    display: inline-block;
    width: 290px;
  }
}

.creat-img2 {
  position: relative;
  height: 681px;
  width: 454px;
  img {
    z-index: 3;
    height: 681px;
    width: 454px;
  }
  .qrcode {
    position: absolute;
    top: 10.7rem;
    left: 50%;
    margin-left: -122px;
    z-index: 5;
  }
  .agent-text {
    z-index: 99;
    position: absolute;
    left: 50%;
    margin-left: -142px;
    top: 428px;
    color: #000000;
    font-size: 14px;
    text-align: center;
    display: inline-block;
    width: 290px;
  }
}
</style>

