<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>收款码管理</el-breadcrumb-item>
          <el-breadcrumb-item>收款码列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <div class="header-form">
        <div class="admin_main_block_left">
          <el-form>
            <el-form-item label="激活时间">
              <el-date-picker
                v-model="date"
                @change="getDate" @input="immediUpdate"
                type="daterange"
                range-separator="~"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                                start-placeholder="起始日期"
                end-placeholder="截止日期"
              >
                <i class="el-icon-date"></i>
              </el-date-picker>
            </el-form-item>
            <el-form-item label="绑定状态">
              <el-select
                v-model="where.isBanding"
                placeholder="请选择"
                clearable
                style="width: 100px"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="已绑定" value="1"></el-option>
                <el-option label="未绑定" value="0"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="关键字">
              <el-input
                v-model="where.keyWord"
                placeholder="收款码编号/商家ID/门店名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" plain icon="el-icon-search" @click="listData()"
                >条件筛选</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="admin_main_block_right">
          <el-row :gutter="20">
            <el-col>
              <!--                            <el-button type="primary" disabled icon="el-icon-tickets">导出数据</el-button>-->
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="admin_table_main">
        <div class="admin_table_main">
          <el-table :data="list" @selection-change="handleSelectionChange">
            <el-table-column prop="payQrcodeNo" label="序号" width="50px">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column prop="batchNo" label="批次编号"></el-table-column>
            <el-table-column
              prop="payQrcodeNo"
              label="收款码编号"
            ></el-table-column>
            <el-table-column
              prop="merchantId"
              label="所属商家ID"
            ></el-table-column>
            <el-table-column prop="shopName" label="所属门店"></el-table-column>
            <el-table-column prop="merchantId" label="收款码牌">
              <template slot-scope="scope">
                <el-image
                  src="/pc/icon_qrcode1.png"
                  @click="qrcodeDialog(scope.row, 1)"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="merchantId" label="语音收款码">
              <template slot-scope="scope">
                <el-image
                  src="/pc/icon_qrcode2.png"
                  @click="qrcodeDialog(scope.row, 2)"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column
              prop="deviceName"
              label="绑定设备"
            ></el-table-column>
            <el-table-column
              prop="bandingDate"
              label="激活时间"
            ></el-table-column>
            <el-table-column
              prop="merchantId"
              label="收款码状态"
            ></el-table-column>
            <el-table-column label="操作" fixed="right" width="125px">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.isBanding"
                  type="info"
                  icon="el-icon-check"
                >
                  已绑定
                </el-button>
                <el-button
                  v-else
                  type="primary"
                  icon="el-icon-plus"
                  @click="cancelDialog(scope.row)"
                >
                  选择商家
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <div class="admin_table_main_pagination">
            <el-pagination
              @current-change="currentChange"
              @size-change="handleSizeChange"
              background
              :total="totalData"
              :page-size="pageSize"
              :current-page="currentPage"
              :page-sizes="[10, 20, 50, 100]"
              layout="sizes, prev, pager, next, total, jumper"
            ></el-pagination>
          </div>
        </div>
        <shop @dialog="getDialog" :row="row" v-if="dialogCancelVisible"></shop>
        <qrcode
          @dialog="getDialog"
          :qrcodeData="qrcodeData"
          v-if="dialogQrcodeVisible"
        ></qrcode>
        <batch
          @dialog="getDialog"
          :qrcodeData="qrcodeData"
          v-if="dialogBatchVisible"
        ></batch>
      </div>
    </div>
  </div>
</template>
<script>
import shop from "@/components/admin/payment_code/shop.vue";
import qrcode from "@/components/admin/payment_code/qrcode.vue";
import batch from "@/components/admin/payment_code/batch.vue";

export default {
  components: {
    shop,
    qrcode,
    batch,
  },
  props: {
    orderStatus: Number,
  },
  data() {
    return {
      list: [],
      date: "",
      row: "",
      dialogCancelVisible: false,
      dialogQrcodeVisible: false,
      dialogBatchVisible: false,
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      selectId: "",
      goods_verify_count: 0,
      where: {
        pageNum: 1,
        pageSize: 20,
        startDate: "",
        endDate: "",
        isBanding: "",
        keyWord: "",
        batchNo: "",
      },
      qrcodeData: {},
    };
  },
  watch: {},
  methods: {
    listData() {
      this.where.pageSize = this.pageSize;
      this.where.pageNum = this.currentPage;
      this.where.batchNo = !(this.$route.query.batchNo)
        ? ""
        : this.$route.query.batchNo;
      this.$post(this.$api.payCodeList, this.where).then((res) => {
        if (res.code === 1) {
          this.totalData = res.data.total;
          this.list = res.data.records;
        }
      });
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v);
      });
      this.selectId = ids;
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    getDate(obj) {
      if (obj) {
        this.where.startDate = obj[0];
        this.where.endDate = obj[1];
      }
    },
    immediUpdate(e) {
      this.$nextTick(() => {
        console.log(e)
        if(e==null){
          this.where.startDate = '';
          this.where.endDate = '';
        }
      });
    },
    cancelDialog(row) {
      this.row = row;
      this.dialogCancelVisible = true;
    },
    getDialog(val) {
      this.dialogCancelVisible = val;
      this.dialogQrcodeVisible = val;
    },
    qrcodeDialog(row, val) {
      if (val === 1) {
        this.qrcodeData = {
          data: row,
          dialogWidth: "635px",
          size: 250,
          bgcolor: "/pc/qrcode1.png",
          type: 1,
        };
      }
      if (val === 2) {
        this.qrcodeData = {
          data: row,
          dialogWidth: "492px",
          size: 244,
          bgcolor: "/pc/qrcode2.png",
          type: 2,
        };
      }
      this.row = row;
      this.dialogQrcodeVisible = true;
    },
    batchDialog() {
      this.dialogBatchVisible = true;
    },
  },
  created() {
    this.listData();
  },
};
</script>
<style lang='scss' scoped>
.el-image {
  cursor: pointer;
}
.el-range-editor--small.el-input__inner {
  width: 250px;
}
.el-image {
  height: 45px;
  width: 33px;
}
</style>

